.bg,
.bodyright,
.tyler,
.bodyleft,
.teagan {
  position: absolute;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}

.bg {
  min-width: 60ch;
  min-height: 60ch;
  width: 40vw;
  height: 40vw;
  max-width: 75ch;
  max-height: 75ch;
  background-image: url('../../../assets/conference_table.jpg');
}

@media (max-width:599.95px) {
  .bg {
    min-width: 30ch;
    min-height: 30ch;
  }

  .bodyright,
  .bodyleft {
    max-width: 13ch;
    max-height: 13ch;
  }

  .tyler,
  .teagan {
    max-width: 8ch;
    max-height: 8ch;
  }
}


.bodyright {
  width: 25ch;
  height: 25ch;
  background-image: url('../../../assets/teagan_body.png');
}

.tyler {
  opacity: 0.9;
  width: 20ch;
  height: 20ch;
  background-image: url('../../../assets/tyler.png');
}

.bodyleft {
  width: 25ch;
  height: 25ch;
  background-image: url('../../../assets/tyler_body.png');
}

.teagan {
  width: 20ch;
  height: 20ch;
  background-image: url('../../../assets/teagan.png');
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
